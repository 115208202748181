@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary-button-color: #0b5ed7;
  --success-button-color: #157347;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito", sans-serif;
  --white: #FFFFFF;
  --black: #303030;
  --primary-color: #FFC06E;
  --secondary-color: #008036;
  --tertiary-color: #75CE9F;
  --light-green: #BDD99E;
  --dark-green: #01A66F;
  --success-color: #00B74A;
  --failure-color: #F93154;

}

.btn-primary {
  background-color: var(--primary-button-color);
}

.btn-primary:hover {
  background-color: var(--black);
}

.btn-success {
  background-color: var(--success-button-color);
}

.btn-success:hover {
  background-color: var(--success-color);
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  font-weight: bold;
  color: var(--primary-button-color);
}

.active-link {
  border-bottom: 2px solid var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
