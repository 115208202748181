.bg-primary-o {
    background-color: var(--primary-color);
    color: var(--secondary-color);
}

.bg-secondary-o {
    color: var(--primary-color);
    background-color: var(--secondary-color);
}

.bg-light-green-o {
    background-color: var(--light-green);
}


.light-green-o {
    color: var(--light-green);
}

.success-color {
    color: var(--success-color);
}

.failure-color {
    color: var(--failure-color);
}

.circles {
    background-color: var(--black);
    width: 10px;
    border-radius: 50%;
    height: 10px;
}

.profile-image {
    width: 15rem;
}

.white {
    color: var(--white);
}


.background-image-home {
    background-image: url("./Assets/background-home.gif");
    min-height: 600px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.login-background {
    background-image: url("./Assets/Login.png");
    min-height: 600px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.background-image-about {
    background-image: url("./Assets/background-about.gif");
    min-height: 600px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.background-image-courses {
    background-image: url("./Assets/background-courses.png");
    min-height: 600px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.background-image-experience {
    background-image: url("./Assets/background-experience.png");
    min-height: 600px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    background-attachment: fixed;
}

.background-image-consultation {
    background-image: url("./Assets/background-consultation.png");
    min-height: 600px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

/* PROGRESS BAR */

/* Default horizontal layout for desktop */
.progress-bar-o {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.progress-o {
    width: 100%;
    height: 20px;
    background-color: #ccc;
    position: relative;
}

.progress-fill-o {
    height: 100%;
    background-color: #007bff;
    transition: width 0.3s ease-in-out;
}

/* Vertical layout for mobile */
@media (max-width: 768px) {
    .progress-bar-o {
        flex-direction: row;
    }

    .steps-o {
        display: flex;
        flex-direction: column;
    }

    .step-o {
        margin: 0.5rem;
        padding: 0.5rem;
        cursor: pointer;
        background-color: #f1f1f1;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .background-image-home {
        background-image: url("./Assets/background-home-mob.gif");
        min-height: 500px;
        background-repeat: no-repeat;
        background-position:right;
        background-size: cover;
        background-attachment: fixed;
    }
    .background-image-about {
        background-image: url("./Assets/background-about-mob.gif");
        min-height: 500px;
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        background-attachment: fixed;
    }
    .background-image-courses {
        background-image: url("./Assets/background-courses-mob.png");
        min-height: 500px;
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        background-attachment: fixed;
    }
    .background-image-experience {
        background-image: url("./Assets/background-experience-mob.png");
        min-height: 500px;
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        background-attachment: fixed;
    }
    .background-image-consultation {
        background-image: url("./Assets/background-consultation-mob.png");
        min-height: 500px;
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        background-attachment: fixed;
    }
}